// Amazon Web Services

import axios from 'axios'
import aws from 'aws-sdk'
import useNotifications from '@/composables/useNotifications'
import i18n from "@/libs/i18n";
import endpoints from '@/libs/endpoints'
import PostalMime from 'postal-mime';
import store from '@/store'
import { rrulestr } from 'rrule'
import realmConnection from '@/views/habit/realm'

export default function awsConnection() {

  const { showErrorMessage } = useNotifications()
  const { invokeFunction, createItems, updateItems, getItem, getItems, ObjectId } = realmConnection()
  const getUserData = store.state?.userStore?.userData
  const clientId =  getUserData?.client?.$oid

  // AWS Configuration
  const getConfig = (accessKey, secretAccessKey) => {
    const config = {
      accessKeyId: accessKey,
      secretAccessKey: secretAccessKey,
      region: process.env.VUE_APP_AWS_REGION,
    };
  
    return config;
  }

  // Access keys from Realm Values
  const getAccessKeys = async (valueNames) => {
    try {
      const { secrets } = await invokeFunction({ name: 'getSecretValues', arg: { valueNames }  })

      return secrets
    } catch (error) {
      console.log(error)
    }
  }

  // S3 Buckets

  const valueNamesS3 = [
    process.env.VUE_APP_AWS_S3_ACCESS_KEY_VALUE_NAME,
    process.env.VUE_APP_AWS_S3_SECRET_ACCESS_KEY_VALUE_NAME
  ]

  const getS3Config = (accessKey, secretAccessKey) => {
    aws.config.update({
      accessKeyId: accessKey,
      secretAccessKey: secretAccessKey,
    })
  
    return new aws.S3({
      signatureVersion: 'v4',
      region: process.env.VUE_APP_AWS_REGION,
    })
  }

  const singleUpload = async (fileInfo, destinationFolder) => {
    try {
      // Get S3 access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesS3)

      // S3 configuration
      const s3 = getS3Config(accessKey, secretAccessKey)

      const key = destinationFolder + '/' + Date.now() + '-' + fileInfo.name.replace(/\s/g, '-')
      const params = {
        Bucket: endpoints.aws_bucket,
        Key: key,
        Expires: 10,
        ContentType: fileInfo.type,
      }
      const url = s3.getSignedUrl('putObject', params)

      return new Promise((resolve, reject) => {
        axios
          .put(url, fileInfo.base64 ? fileInfo.base64 : fileInfo, {
            headers: {
              'Content-Type': fileInfo.type,
            },
          })
          .then(result => {
            const bucketUrl = decodeURIComponent(result.request.responseURL).split(key)[0]
            result.key = key
            result.fullPath = bucketUrl + key
            resolve(key)
          })
          .catch(() => {
            showErrorMessage(i18n.t('message.upload_file_error'))
            reject()
          })
      })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.upload_file_error'))
    }
  }
    
  const getFile = async (bucket, key) => {
    try {
      // Get S3 access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesS3)

      // S3 configuration
      const s3 = getS3Config(accessKey, secretAccessKey)

      return new Promise((resolve, reject) => {
        s3.getObject(
          { Bucket: bucket, Key: key },
          function (error, data) {
            if (error != null) {
              reject(error)
            } else {
              let base64Img = new TextDecoder().decode(data.Body)
              resolve(base64Img)
            }
          }
        )
      })
    } catch (error) {
      console.log(error)
    }
  }

  // SES (Amazon Simple Email Service)

  const valueNamesSES = [
    process.env.VUE_APP_AWS_SES_ACCESS_KEY_VALUE_NAME,
    process.env.VUE_APP_AWS_SES_SECRET_ACCESS_KEY_VALUE_NAME
  ]

  const sendEmail = async (emailList, subject, body) => {
    try {
      // Get SES access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesSES)
  
      // SES configuration
      const AWS_SES = new aws.SES(getConfig(accessKey, secretAccessKey))
  
      const params = {
        Source: process.env.VUE_APP_AWS_SOURCE_EMAIL,
        Destination: {
          ToAddresses: emailList,
        },
        ReplyToAddresses: [],
        Message: {
          Body: {
            Html: {
              Charset: 'UTF-8',
              Data: body,
            },
          },
          Subject: {
            Charset: 'UTF-8',
            Data: subject,
          }
        },
        // Send emails using the SES default dedicated IP addresses pool.
        ConfigurationSetName: process.env.VUE_APP_AWS_CONFIGURATION_SET,
      };
  
      return AWS_SES.sendEmail(params).promise();
    } catch (error) {
      console.log(error)
    }
  };

  // SQS (Simple Queue Service)

  const valueNamesSQS = [
    process.env.VUE_APP_AWS_SQS_ACCESS_KEY_VALUE_NAME,
    process.env.VUE_APP_AWS_SQS_SECRET_ACCESS_KEY_VALUE_NAME
  ]

  let AWS_SQS
  let params

  const getMessagesFromQueue = async () => {
    if (!AWS_SQS) {
      // Get SQS access keys from Realm Values
      const [ accessKey, secretAccessKey ] = await getAccessKeys(valueNamesSQS)
    
      // SQS configuration
      AWS_SQS = new aws.SQS(getConfig(accessKey, secretAccessKey))
    
      params = {
        QueueUrl: endpoints.aws_queue_url,
        MaxNumberOfMessages: 1, // Valid values: 1 to 10. Default: 1.
        VisibilityTimeout: 5,
        WaitTimeSeconds: 3, // Set to 0 for short polling, >0 for long polling
      }
    }

    AWS_SQS.receiveMessage(params, handleMessage)
  }

  const handleMessage = (err, data) => {
    if (err) {
      console.error('Error receiving message:', err);
    } else if (data.Messages) {
      const message = data.Messages[0];

      if (message) {
        // Get email from S3 Buckets
        const messageBody = JSON.parse(message.Body)

        if (messageBody.Records?.length) {
          const { s3: { bucket: { name: bucketName }, object: { key: objectKey } } } = messageBody.Records[0]
          getFile(bucketName, objectKey)
            .then(emailContent => handleEventFromEmail(emailContent, message.ReceiptHandle))
            .catch(error => console.log(error))
        }

        // Look up for another message in the queue
        AWS_SQS.receiveMessage(params, handleMessage)
      }
    }
  }

  const handleEventFromEmail = async (emailContent, receiptHandle) => {
    // Parse email content
    const parser = new PostalMime()
    const emailParsed = await parser.parse(emailContent)

    // Get sender data
    const senderEmail = emailParsed?.from?.address
    const senderData = await getSenderData(senderEmail)

    // Get recipients data
    const recipientEmails = emailParsed?.to?.map(e => e.address)
    const recipientsData = await getRecipientsData(recipientEmails)

    // Extract id and dates from calendar data
    const [method, calendarId, startDates, endDates, recurrenceId] = parseCalendarData(emailContent)

    // Define events payload
    const eventData = {
      client_id: ObjectId(clientId),
      isInstance: true,
      extendedProps: { calendar: 'Pendientes' },
      outlookCalendarId: calendarId,
      metadata: [
        {
          name: 'instance',
          type: 'select',
          answer: emailParsed?.subject || ''
        },
        {
          name: 'instance_leader',
          type: 'select',
        }
      ]
    }

    if (senderData) {
      eventData.organizer = senderData._id
      eventData.metadata[1].answer = senderData.name
    }

    if (recipientsData?.length) {
      eventData.participants = recipientsData.map(e => e._id)
    }

    let payload = startDates.map((e, i) => {
      return {
        ...eventData,
        start: e,
        end: endDates[i],
        outlookRecurrenceId: e.getTime()
      }
    })

    let eventId = null

    // Check if event already exists in database. If it exists, then an update operation is executead instead of create
    if (calendarId) {
      try {
        eventId = await getEventId(calendarId, recurrenceId)
        if (eventId) {
          if (method === "CANCEL") payload = { deleted: true, deletedJustification: "Deleted from Outlook" }
          else {
            payload = { participants: eventData.participants || [] }
            // The date is only updated for single events (TODO: implement logic to update the dates of recurring events also)
            if (startDates.length === 1) payload.start = startDates[0]
            if (endDates.length === 1) payload.end = endDates[0]
          }
        }
      } catch (error) {
        return console.log(error)
      }
    }

    // Create or update events
    try {
      const collection = 'event'

      if (eventId) {
        const query = { outlookCalendarId: calendarId }
        if (recurrenceId) query.outlookRecurrenceId = recurrenceId
        const action = { $set: payload }

        await updateItems({ collection, query, action })
      } else {
        await createItems({ collection, payload })
      }
      
      // Increment the instancesFromEmail store value
      if (store.hasModule("calendar")) store.commit('calendar/INCREMENT_INSTANCES')

      // Delete message from queue
      deleteMessageFromQueue(receiptHandle)
    } catch (error) {
      console.log(error)
    }
  }

  const parseCalendarData = (emailContent) => {
    let method = null
    let calendarId = null
    let recurrenceId = null
    let startDates = [new Date()]
    let endDates = [new Date(startDates[0])]
    endDates[0].setHours(endDates[0].getHours() + 1)

    // Regular expression pattern for the text/calendar section of the email content
    const pattern = /--_.*Content-Type: text\/calendar;.*Content-Transfer-Encoding: base64.*?([a-zA-Z0-9+/=\s]+)--_.*$/s

    // Extract the base64 content using the pattern
    const match = emailContent.match(pattern)

    if (match) {
      const base64Content = match[1]
      
      // Decode the base64 content
      const decodedContent = atob(base64Content)
      
      // Define a regular expression to match the METHOD, UID, RRULE, DTSTART and DTEND property with variable TZID (time zone)
      const methodRegex = /METHOD:([^\r\n]+)/
      const uidRegex = /UID:(.*(?:\r?\n\s+.*)*)/
      const dtstartRegex = /DTSTART;TZID=([^:]+):(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/
      const dtendRegex = /DTEND;TZID=([^:]+):(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/
      const rruleRegex = /RRULE:(.*)/i
      const recurrenceIdRegex = /RECURRENCE-ID;TZID=([^:]+):(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})/

      // Use the regular expression to match the METHOD, UID, DTSTART and DTEND values
      const methodMatch = decodedContent.match(methodRegex)
      const uidMatch = decodedContent.match(uidRegex)
      const dtstartMatch = decodedContent.match(dtstartRegex)
      const dtendMatch = decodedContent.match(dtendRegex)
      const rruleMatch = decodedContent.match(rruleRegex)
      const recurrenceIdMatch = decodedContent.match(recurrenceIdRegex)

      method = methodMatch && methodMatch[1]

      if (uidMatch && uidMatch[1]) {
        // Remove line breaks and extra spaces
        calendarId = uidMatch[1].replace(/\r?\n\s+/g, '')
      }

      if (dtstartMatch && dtendMatch) {
        // Extract components from the match
        const [, , yearStart, monthStart, dayStart, hoursStart, minutesStart, secondsStart] = dtstartMatch
        const [, , yearEnd, monthEnd, dayEnd, hoursEnd, minutesEnd, secondsEnd] = dtendMatch

        // Create a JavaScript Date object
        startDates[0] = new Date(`${yearStart}-${monthStart}-${dayStart}T${hoursStart}:${minutesStart}:${secondsStart}`)
        endDates[0] = new Date(`${yearEnd}-${monthEnd}-${dayEnd}T${hoursEnd}:${minutesEnd}:${secondsEnd}`)
      }

      // See if event is recurrent
      if (rruleMatch && rruleMatch[1]) {
          const recurrenceRuleString = rruleMatch[1].trim()

          // Parse the recurrence rule string into an RRule object
          const rule = rrulestr(recurrenceRuleString, { dtstart: startDates[0] })

          // Generate the occurrence dates based on the rule
          const dateDifference = endDates[0] - startDates[0]
          startDates = rule.all()
          endDates = startDates.map(e => {
            return new Date(e.getTime() + dateDifference)
          })
      }

      // See if event has a recurrence id
      if (recurrenceIdMatch) {
        // Extract components from the match
        const [, , yearStart, monthStart, dayStart, hoursStart, minutesStart, secondsStart] = recurrenceIdMatch

        // Create a JavaScript Date object
        const recurrenceIdDate = new Date(`${yearStart}-${monthStart}-${dayStart}T${hoursStart}:${minutesStart}:${secondsStart}`)
        recurrenceId = recurrenceIdDate.getTime()
      }
    }

    return [method, calendarId, startDates, endDates, recurrenceId]
  }

  const getSenderData = async (senderEmail) => {
    try {
      const query = {
        client_id: ObjectId(clientId),
        deleted: { $ne: true },
        email: senderEmail
      }

      return await getItem({ collection: 'worker', query })
    } catch (error) {
      console.log(error)
    }
  }

  const getRecipientsData = async (recipientEmails) => {
    try {
      const query = {
        client_id: ObjectId(clientId),
        deleted: { $ne: true },
        email: { $in: recipientEmails }
      }

      return await getItems({ collection: 'worker', query })
    } catch (error) {
      console.log(error)
    }
  }

  const getEventId = async (outlookCalendarId, outlookRecurrenceId) => {
    try {
      const query = { outlookCalendarId }
      if (outlookRecurrenceId) query.outlookRecurrenceId = outlookRecurrenceId

      const item = await getItem({ collection: 'event', query })
      return item?._id
    } catch (error) {
      console.log(error)
    }
  }

  const deleteMessageFromQueue = (receiptHandle) => {
    const deleteParams = {
      QueueUrl: endpoints.aws_queue_url,
      ReceiptHandle: receiptHandle,
    };

    AWS_SQS.deleteMessage(deleteParams, (deleteErr) => {
      if (deleteErr) console.error('Error deleting message:', deleteErr);
    });
  }

  return {
    singleUpload,
    getFile,
    sendEmail,
    getMessagesFromQueue
  }
}